import Base from '../../_config/base';

class VideoBlock extends Base {
  constructor(el) {
    super(el);

    const playBtn = el.querySelector('.playBtn');
    const video = el.querySelector('.videoBlock__wrapper .video');

    // const exitHandler = () => {
    //   if (document.fullscreenElement === null) {
    //     video.removeAttribute('controls');
    //     video.pause();
    //     video.currentTime = 0;
    //     video.load();
    //     playBtn.classList.add('show');
    //     document.exitFullscreen();
    //   }
    // };

    const playToggle = () => {
      playBtn.classList.remove('show');

      video.play();
      video.setAttribute('controls', true);

      // if (video.mozRequestFullScreen) {
      //   video.mozRequestFullScreen();
      // }

      // if (video.webkitRequestFullScreen) {
      //   video.webkitRequestFullScreen();
      // }
    };

    playBtn.addEventListener('click', () => playToggle());

    video.addEventListener('pause', () => {
      video.removeAttribute('controls');
      playBtn.classList.add('show');
    });

    // document.addEventListener('fullscreenchange', () => exitHandler());
    // document.addEventListener('mozfullscreenchange', () => exitHandler());
    // document.addEventListener('MSFullscreenChange', () => exitHandler());
    // document.addEventListener('webkitfullscreenchange', () => exitHandler());
  }
}

VideoBlock.className = 'VideoBlock';
export default VideoBlock;
