import Button from './01-atoms/button/button';
import Attachment from './01-atoms/inputs/attachment';
import Range from './01-atoms/inputs/range';
import Select from './01-atoms/inputs/select';
// import Share from './02-molecules/share/share';
import Accordion from './02-molecules/accordion/accordion';
import Alerts from './02-molecules/alerts/alerts';
import Popup from './02-molecules/popup/popup';
import Slider from './02-molecules/slider/slider';
import Teasers from './02-molecules/threeTeaser/teasers';
import Filter from './02-molecules/filter/filter';
import Dialogues from './02-molecules/dialogues/dialogues';
import Header from './03-organisms/header/header';
import Location from './02-molecules/location/location';
import Map from './02-molecules/location/map';
import AreaMap from './02-molecules/areaMap/areamap';
// import Previewcanvas from './02-molecules/areaMap/previewcanvas';
import Mappopup from './02-molecules/areaMap/mappopup';
// import Bildslider from './02-molecules/areaMap/bildslider';
import Form from './02-molecules/contactForm/form';
import Partnerslist from './02-molecules/partnersList/partnerslist';
// import Search from './02-molecules/partnersList/search';
import Bildslider from './02-molecules/areaMap/bildSlider';
import VideoBlock from './02-molecules/videoBlock/videoBlock';

require('./main.scss');

function ready(fn) {
  if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

ready(() => {
  const html = document.querySelector('html');
  const Component = window.Streusel || {};

  html.classList.remove('no-js');

  Component.Button = Button.init();
  Component.Range = Range.init();
  Component.Dialogues = Dialogues.init();
  // Component.Share = Share.init();
  Component.Accordion = Accordion.init();
  Component.Alerts = Alerts.init();
  Component.Popup = Popup.init();
  Component.Slider = Slider.init();
  Component.Attachment = Attachment.init();
  Component.Select = Select.init();
  Component.Header = Header.init();
  Component.Teasers = Teasers.init();
  Component.Filter = Filter.init();
  Component.Location = Location.init();
  Component.Map = Map.init();
  Component.AreaMap = AreaMap.init();
  Component.Form = Form.init();
  Component.Partnerslist = Partnerslist.init();
  // Component.Search = Search.init();
  Component.Mappopup = Mappopup.init();
  Component.Bildslider = Bildslider.init();
  Component.VideoBlock = VideoBlock.init();
  // Component.Previewcanvas = Previewcanvas.init();
});
